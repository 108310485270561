<template>
  <div>
    <!-- <div class="page-header clear-filter" filter-color="orange"> -->
    <div class="page-header clear-filter">
      <parallax
        class="page-header-image"
        style="background-image: url('img/header.jpg')"
      >
      </parallax>
      <div class="container">
        <div class="content-center brand">
          <!-- <div class="row"> -->
          <!-- <img class="n-logo" src="img/logo2.png" alt="" /> -->
          <h1 class="h1-seo">WILLKOMMEN</h1>

          <!-- </div> -->
          <!-- <div class="row">
            <div class="col-md-12">
              <div class="hero-images-container-2">
                <img src="img/ekolTaxiSchule.jpg" width="400px" alt="" />
              </div>
            </div>
          </div> -->
        </div>
        <!-- <div class="section section-images"> -->
        <!-- <div class="container"></div> -->
        <!-- <h6 class="category category-absolute">
          Designed by
          <a href="http://invisionapp.com/" target="_blank">
            <img src="img/invision-white-slim.png" class="invision-logo" /> </a
          >. Coded by
          <a href="https://www.creative-tim.com" target="_blank">
            <img
              src="img/creative-tim-white-slim2.png"
              class="creative-tim-logo"
            /> </a
          >.
        </h6> -->
      </div>
    </div>
    <!-- <div class="main">
      <div class="section section-images">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="hero-images-container">
                <img src="img/ekolTaxiSchule.jpg" alt="" />
              </div>
              <div class="hero-images-container-1">
                <img src="img/ekolTaxiSchule.jpg" alt="" />
              </div>
              <div class="hero-images-container-2">
                <img src="img/ekolTaxiSchule.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import { Parallax } from "../components";
// import BasicElements from "./componentSamples/BasicElementsSection";
// import Navigation from "./componentSamples/Navigation";
// import TabsSection from "./componentSamples/Tabs";
// import ProgressPagination from "./componentSamples/ProgressPagination";
// import Notifications from "./componentSamples/Notifications";
// import Typography from "./componentSamples/Typography";
// import JavascriptComponents from "./componentSamples/JavascriptComponents";
// import CarouselSection from "./componentSamples/CarouselSection";
// import NucleoIconsSection from "./componentSamples/NucleoIconsSection";
// import SignupForm from "./componentSamples/SignupForm";
// import ExamplesSection from "./componentSamples/ExamplesSection";
// import DownloadSection from "./componentSamples/DownloadSection";

export default {
  name: "index",
  bodyClass: "index-page",
  components: {
    Parallax,
  },
};
</script>
<style></style>
