<template>
  <navbar
    position="fixed"
    type="primary"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
  >
    <template>
      <router-link
        v-popover:popover1
        class="navbar-brand "
        to="/"
      >
        <img src="img/logo.png"/>
      </router-link>
      <!-- <el-popover
        ref="popover1"
        popper-class="popover"
        placement="bottom"
        width="200"
        trigger="hover"
      >
        <div class="popover-body">
          Designed by Invision. Coded by Creative Tim
        </div>
      </el-popover> -->
    </template>
    <template slot="navbar-menu">
      <li class="nav-item" v-if="loginButton === false && !isAdmin()">
        <button
          v-popover:popover1
          class="navbar-brand btn btn-question"
           @click="routeToPage('examChoose')"
        >
          Prüfung
        </button>
      </li>
      <!-- <li class="nav-item" v-if="loginButton === false && !isAdmin()">
        <router-link
          v-popover:popover1
          class="navbar-brand btn btn-question"
          to="/lesenChoose"
        >
          Lesen
        </router-link>
      </li> -->
      <li class="nav-item" v-if="loginButton === false && !isAdmin()">
        <button
          v-popover:popover1
          class="navbar-brand  btn btn-question"
          data-bs-toogle="collapse"
          @click="routeToPage('result')"

        >
          Ergebnis
        </button>
      </li>
      <li class="nav-item" v-if="loginButton === false && isAdmin()">
        <button
          v-popover:popover1
          class="navbar-brand  btn btn-question"
           @click="routeToPage('userList')"
        >
          Benutzer List
        </button>
      </li>
      <li class="nav-item" v-if="loginButton === false && isAdmin()">
        <button
          v-popover:popover1
          class="navbar-brand  btn btn-question"
           @click="routeToPage('questionList')"
        >
          Fragen List
        </button>
      </li>
      
      <li class="nav-item" v-if="loginButton === false">
        <button
          v-popover:popover1
          class="navbar-brand  btn btn-question"
          @click="logout"
        >
          <!-- {{ currentUser.userName }}(Ausloggen) -->
          Ausloggen - {{ currentUser.userName }} - bis {{ $moment(currentUser.blockedDate).format("DD-MM-YYYY") }}
        </button>
      </li>
      <li class="nav-item" v-if="loginButton">
        <button
          v-popover:popover1
          class="navbar-brand  btn btn-question"
           @click="routeToPage('login')"

          
        >
          Einloggen
        </button>
      </li>
    </template>
  </navbar>
</template>

<script>
import { Navbar } from "@/components";
import { Popover } from "element-ui";
import { mapState } from "vuex";

export default {
  name: "main-navbar",
  props: {
    transparent: Boolean,
    colorOnScroll: Number,
  },
  components: {
    Navbar,
    [Popover.name]: Popover,
  },
  data() {
    return {
      currentUser: null,
      loginButton: true,
    };
  },
  mounted() {
    this.currentUser = this.$store.getters.getUser;
    if (this.currentUser && this.currentUser.id) {
      this.loginButton = false;
      this.$store.commit("setShowLoginButton", false);
    } else {
      this.loginButton = true;
      this.$store.commit("setShowLoginButton", true);
    }
  },
  computed: mapState(["showLoginButton"]),
  methods: {
    logout() {
      this.currentUser = null;
      this.$store.commit("setShowLoginButton", true);
      this.$store.dispatch("AUTH_LOGOUT").then(() => {
        this.$router.push("/login");
      });
    },
    routeToPage(pageName) {
        this.$router.push('/'+pageName);
         document.getElementById("navbarButton").click();
    },
    isAdmin() {
      return (
        this.currentUser != null &&
        this.currentUser.userRole != null &&
        this.currentUser.userRole.id == 1
      );
    },
  },
  watch: {
    showLoginButton: function (newValue, oldValue) {
      this.currentUser = this.$store.getters.getUser;
      this.loginButton = newValue;
    },
  },
};
</script>

<style scoped></style>
