<template>
  <div class="container">
    <div class="card">
      <div
        class="tab-content tab-space text-left tab-content-padding text-center"
      >
        <div class="container">
          <div class="row">
            <div class="col-md-3" style="display: flex; justify-content: center; align-items: center;">
              <!-- <div class="row"> -->
                {{ questionResult.questionText }}
              <!-- </div> -->
              <div v-if="questionResult.imageUrl">
                <img :src="questionResult.imageUrl" style="height: 100px" />
              </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-8">
              <div class="row" :style="getStyle(questionResult, 'a')">
                a)&nbsp;&nbsp; {{ questionResult.choiseAText }}
              </div>
              <div class="row" :style="getStyle(questionResult, 'b')">
                b)&nbsp;&nbsp; {{ questionResult.choiseBText }}
              </div>
              <div class="row" :style="getStyle(questionResult, 'c')">
                c)&nbsp;&nbsp; {{ questionResult.choiseCText }}
              </div>
              <div class="row" :style="getStyle(questionResult, 'd')">
                d)&nbsp;&nbsp;{{ questionResult.choiseDText }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    questionResult: Object,
  },
  methods: {
    getStyle(question, choice) {
      let currentChoice = "choise" + choice.toUpperCase() + "IsFake";
      if (question[currentChoice] == 0) {
        return "background: green; color:white; margin:10px 10px 10px 10px; text-align:left ";
      } else {
        if (question[choice] == question.selectedChoise) {
          return "background: red; color:white; margin:10px 10px 10px 10px; text-align:left";
        }
      }
      return "margin:10px 10px 10px 10px; text-align:left ;";
    },
  },
};
</script>

<style>
</style>