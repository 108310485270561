<template>
  <div>
    <div class="page-header clear-filter">
      <div class="container">
        <div class="card">
          <div class="tab-content tab-space text-left tab-content-padding text-center">
            <div
            class="row"
            style="
            color: black;
            font-weight: bold;
            text-align: center;
            justify-content: center;
            font-size: large;
            "
            >
            Bitte Prüfung Type wählen
            </div>
            <div class="container">
              <div style="display: flex; flex-wrap: wrap">
                <div class="col-12 col-sm-4">
                  <div col-12>
                    <router-link
                    class="btn  btn-question" style="width:80%"
                    :to="{
                    name: 'exam',
                    params: {
                    examType: 'all',
                    },
                    }"
                    >
                      <img src="img/ganzeP.jpg"/>
                    </router-link>
                  </div>
                  <div >
                    <h3 style="color:black; "> Ganze Prüfung</h3>
                  </div>
                </div>
                <div class="col-12 col-sm-4">
                  <div col-12>
                    <router-link
                    class="btn  btn-question" style="width:80%"
                    :to="{
                    name: 'exam',
                    params: {
                    examType: 'block1',
                    },
                    }"
                    >
                      <img src="img/block1.jpg"/>
                    </router-link>
                  </div>
                  <div >
                    <h3 style="color:black; "> Block 1</h3>
                  </div>
                </div>
                <div class="col-12 col-sm-4">
                  <div col-12>
                    <router-link
                    class="btn  btn-question" style="width:80%"
                    :to="{
                    name: 'exam',
                    params: {
                    examType: 'block2',
                    },
                    }"
                    >
                      <img src="img/block2.jpg"/>
                    </router-link>
                  </div>
                  <div >
                    <h3 style="color:black; "> Block 2</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-content tab-space text-left tab-content-padding text-center">
            <div
            class="row"
            style="
            color: black;
            font-weight: bold;
            text-align: center;
            justify-content: center;
            font-size: large;
            "
            >Bitte VOR Prüfung Type wählen
            </div>
            <div class="container">
              <div style="display: flex; flex-wrap: wrap">
                <div class="col-12 col-sm-4">
                  <div col-12>
                    <router-link
                    class="btn" style="width:80%; background-color:white"
                    :to="{
                    name: 'preexam',
                    params: {
                    examType: 'all',
                    },
                    }"
                    >
                      <img src="img/ganzeP.jpg"/>
                    </router-link>
                  </div>
                  <div >
                    <h3 style="color:black; "> Ganze Prüfung</h3>
                  </div>
                </div>
                <div class="col-12 col-sm-4">
                  <div col-12>
                    <router-link
                    class="btn " style="width:80%; background-color:white"
                    :to="{
                    name: 'preexam',
                    params: {
                    examType: 'block1',
                    },
                    }"
                    >
                      <img src="img/block1.jpg"/>
                    </router-link>
                  </div>
                  <div >
                    <h3 style="color:black; "> Block 1</h3>
                  </div>
                </div>
                <div class="col-12 col-sm-4">
                  <div col-12>
                    <router-link
                    class="btn " style="width:80%; background-color:white"
                    :to="{
                    name: 'preexam',
                    params: {
                    examType: 'block2',
                    },
                    }"
                    >
                      <img src="img/block2.jpg"/>
                    </router-link>
                  </div>
                  <div >
                    <h3 style="color:black; "> Block 2</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-content tab-space text-left tab-content-padding text-center">
            <div
            class="row"
            style="
            color: black;
            font-weight: bold;
            text-align: center;
            justify-content: center;
            font-size: large;
            "
            >Bitte Nachsicht Prüfung Type wählen
            </div>
            <div class="container">
              <div style="display: flex; flex-wrap: wrap">
                <div class="col-12 col-sm-4">
                  <div col-12>
                    <router-link
                    class="btn" style="width:80%; background-color:white"
                    :to="{
                    name: 'exam',
                    params: {
                    examType: 'nachSicht',
                    },
                    }"
                    >
                      <img src="img/block2.jpg"/>
                    </router-link>
                  </div>
                  <div >
                    <h3 style="color:black; "> Nachsicht Prüfung</h3>
                  </div>
                </div>
                <div class="col-12 col-sm-4">
                  <div col-12>
                    <router-link
                    class="btn " style="width:80%; background-color:white"
                    :to="{
                    name: 'preexam',
                    params: {
                    examType: 'nachSicht',
                    },
                    }"
                    >
                      <img src="img/block2.jpg"/>
                    </router-link>
                  </div>
                  <div >
                    <h3 style="color:black; "> Nachsicht Vor Prüfung </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>