<template>
  <div class="page-header clear-filter">
    <div class="container" style="color: black">
      <div class="title">
        <router-link
          class="btn btn-round btn-question"
          :to="{
            name: 'resultSummary',
            params: {
              examStudentId: examStudentId,
              examDate: examDate,
              examType: examType,
            },
          }"
        >
          Zurück zur Prüfung von
          {{ $moment(examDate).format("DD-MM-YYYY HH:mm") }}
        </router-link>
      </div>

      <div v-for="question in resultDetail" :key="question.examQuestionId">
        <question-result-overview
          :questionResult="question"
        ></question-result-overview>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionResultOverview from "./QuestionResultOverview";

export default {
  components: {
    QuestionResultOverview,
  },
  data() {
    return {
      baseUrl: "http://" + this.apiBaseHost + ":" + this.apiBasePort,
      resultDetail: {},
      examDate: null,
      examStudentId: null,
      examType: null,
    };
  },
  name: "resultDetail",
  mounted() {
    this.examStudentId = this.$route.params.examStudentId;
    let categoryId = this.$route.params.categoryId;
    this.examDate = this.$route.params.examDate;
    this.examType = this.$route.params.examType;
    if (
      this.examStudentId == null ||
      this.examDate == null ||
      this.examType == null ||
      categoryId == null
    ) {
      this.examStudentId = this.$store.getters.getUserExam;
      this.examDate = this.$store.getters.getUserExamDate;
      this.examType = this.$store.getters.getUserExamType;
      categoryId = this.$store.getters.getUserExamCategory;
    } else {
      this.$store.commit("setUserExam", this.examStudentId);
      this.$store.commit("setUserExamDate", this.examDate);
      this.$store.commit("setUserExamType", this.examType);
      this.$store.commit("setUserExamCategory", categoryId);
    }
    if (this.examStudentId != null) {
      this.$axios
        .post(this.baseUrl +"/getResultDetail", {
          examStudentId: this.examStudentId,
          categoryId: categoryId,
        })
        .then((response) => (this.resultDetail = response.data));
    } else {
      this.$router.push({ path: "result" });
    }
  },
};
</script>

<style>
</style>